import React from "react"
import * as styles from './css/header.module.css'
import Container from './container_header'

const Header = (props) => {
    return (
        <Container>
            <header className={styles.main}>
                <h1 className={styles.line}>Universal Time Complication</h1>
                <h2>{props.captionText}</h2>
                {/* <h1 className={styles.line}>{props.headerText}</h1> */}
                {/* <h2 calssName={styles.line}>{props.captionText}</h2> */}
                {/* <div className={styles.line}></div> */}
            </header>
        </Container>
    )
}



export default Header
