import React from 'react'
import * as styles from './css/footer.module.css'
import { Link } from 'gatsby'
import Container from './container_footer'

const Footer = (props) => {
    return (
        <Container>
            <footer className={styles.main}>
                <p className={styles.copyright}>Copyright &copy; 2021 by Marco Deubel</p>
                <p>
                    <Link className={styles.link} to="/">App</Link> 
                    &nbsp;&middot;&nbsp;
                    <Link className={styles.link} to="/impressum">Impressum/Privacy Policy Website</Link>
                    &nbsp;&middot;&nbsp;
                    <Link className={styles.link} to="/privacypolicy">Privacy Policy App</Link>
                    &nbsp;&middot;&nbsp;
                    <Link className={styles.link} to="/de">German</Link>
                </p>
            </footer>
        </Container>
    )
}

export default Footer
