import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import * as styles from '../components/css/privacypolicy.module.css'
import Container from '../components/container'
import SEO from '../components/seo'

const privacypolicy = () => {
    return (
        <div >
            <SEO title="Privacy Police" description="Privacy Police App" />
            {/* <Header captionText="Privacy Police App" /> */}
            <Header />
            <Container>
            <h2>Privacy Police App</h2>
            <p>
                <div>Universal Time Complication does not collect any data.</div>
                <div>Apple may share some analytics if you opt in.</div>
                <div>If you delete the app, all settings will be deleted as well.</div>
            </p>
            <h2>Contact</h2>
            <p>
                <div>Support: support (at) universaltimecomplication (dot) de</div>
                <div>Feedback: feedback (at) universaltimecomplication (dot) de</div>
            </p>
            </Container>
            <Footer />
        </div>
    )
}

export default privacypolicy
